
import Vue, { PropType } from 'vue'
import { Series } from '~/models/series'
import {
  siteSeriesUrl,
  siteSermonUrl,
  siteShareUrl,
} from '~/assets/ts/utils/urls'
import { Sermon } from '~/models/sermon'
import { DateFormat, PlayerStatus } from '~/assets/ts/enums'
import { localizeDateTime } from '~/assets/ts/utils/date'
import HorizontalSermonSelector from '~/components/_general/HorizontalSermonSelector.vue'
import { SermonApi, SermonRequestOptions } from '~/apiclient/apisermons'

export default Vue.extend({
  name: 'HorizontalSeriesPlayer',
  components: { HorizontalSermonSelector },
  props: {
    seriesID: {
      type: [Number, String],
      required: true,
    },
    options: {
      type: Object as PropType<SermonRequestOptions>,
      default: () => {},
    },
    fadeBgColor: {
      type: String,
      default: 'bg-theme',
      validator(value: string) {
        return value.includes('bg-')
      },
    },
    loadOnMount: {
      type: Boolean,
      default: true,
    },
    shadow: {
      type: Boolean,
    },
  },
  data() {
    return {
      autoplay: false,
      playerStatus: PlayerStatus.Static as PlayerStatus,
      currentSermonID: undefined as string | undefined,
      sermonsApi: [] as SermonApi[],
    }
  },
  computed: {
    series(): Series | undefined {
      if (!this.hasSermons) return undefined
      return this.sermons[0].series
    },
    seriesUrl(): string {
      return siteShareUrl(siteSeriesUrl(this.series))
    },
    hasSermons(): boolean {
      return !!this.sermonsApi.length
    },
    sermons(): Sermon[] {
      return this.sermonsApi.map((s: SermonApi) => new Sermon(s))
    },
    currentSermon(): Sermon | undefined {
      if (!this.currentSermonID) return undefined
      return this.sermons.find((s) => s.id === this.currentSermonID)
    },
    currentSermonUrl(): string {
      return siteShareUrl(siteSermonUrl(this.currentSermon))
    },
    sermonIndex(): number | undefined {
      if (!this.currentSermon) return undefined
      return this.sermons.indexOf(this.currentSermon)
    },
    nextSermonIndex(): number | undefined {
      if (this.sermonIndex === undefined) return undefined
      const reverse = this.$store.getters['player/autoplayNextReverse']
      const dir = reverse ? -1 : 1
      const index = this.sermonIndex + dir
      if (index >= this.sermons.length || index < 0) return undefined
      return index
    },
    nextSermon(): Sermon | undefined {
      if (this.playerStatus !== PlayerStatus.Ended) return
      if (this.nextSermonIndex === undefined) return undefined
      return this.sermons[this.nextSermonIndex]
    },
    currentSermonDate(): string {
      if (!this.currentSermon) return ''
      return localizeDateTime(this.currentSermon.preachDate, DateFormat.Date)
    },
  },
  mounted() {
    if (!this.loadOnMount) return
    this.loadSermons()
  },
  methods: {
    async loadSermons() {
      if (this.hasSermons) return

      // TODO: one day make this load a lite list and just get each sermon as needed
      const { results } = await this.$apiClient.getFilteredSermonList({
        series: this.seriesID.toString(),
        pageSize: 100,
        cache: false,
        requireVideo: true,
        ...this.options,
      })
      this.sermonsApi = results
      if (!this.hasSermons) return
      this.setSermon(this.sermons[0].id, false)
      this.$emit('loaded')
    },
    statusUpdated(status: PlayerStatus) {
      if (status === PlayerStatus.FetchingNext && this.nextSermon) {
        this.currentSermonID = this.nextSermon.id
      }
      this.playerStatus = status
    },
    setSermon(sermonID: string, autoplay = true) {
      this.autoplay = autoplay
      this.currentSermonID = sermonID
    },
  },
})
